import {scrolEvents} from './default';
import {index} from './index';
import {loading} from './loading';
import {vues} from './vue';
let loadflag = true;

function init(controller , scene){
	const load = document.getElementById('loading');

	if(load){
		if(loadflag){
			loading(index);
			loadflag = false;
		}else{
			load.style.display = 'none';
			index();
		}
	}else{
		index();
	}
    scrolEvents(controller , scene);
}

document.addEventListener('DOMContentLoaded', function() {
	let scene = [];
	let controller;
    controller =  new ScrollMagic.Controller();
	init(controller , scene);
	vues();
	//　USE BRABA PJAX
    Barba.Pjax.start();
    Barba.Prefetch.init();
    var move_elem1 = document.querySelector('._move');

	var time = 1800;
    var ShutterAnimation = Barba.BaseTransition.extend({
		start: function() {
			this.shutter(time)
				.then(this.newContainerLoading)
				.then(this.finish.bind(this));
		},

		shutter: function(timer) {
			return new Promise( function (resolve) {
                document.body.classList.remove('fix');
				move_elem1.classList.add('moved');
								
				setTimeout(function(){
					document.documentElement.scrollTop = 0;
					document.body.scrollTop = 0;
					resolve();
					
				},timer/2);

				setTimeout(function () {
					move_elem1.classList.remove('moved');
				},timer);
			});
		},

		finish: function() {
			this.done();
			scene = [];
			init(controller,scene);
		}
    });

    Barba.Dispatcher.on('newPageReady', function(currentStatus, oldStatus, container, newPageRawHTML) {
		const head = document.head;
		const newPageRawHead = newPageRawHTML.match(/<head[^>]*>([\s\S.]*)<\/head>/i)[0];
		const newPageHead = document.createElement('head');
		newPageHead.innerHTML = newPageRawHead;
		const removeHeadTags = [
			"meta[name='keywords']",
			"meta[name='description']",
			"meta[property^='og']",
			"meta[name^='twitter']",
			"meta[itemprop]",
			"link[itemprop]",
			"link[rel='prev']",
			"link[rel='next']",
			"link[rel='canonical']"
			].join(',');
		const headTags = head.querySelectorAll(removeHeadTags);
		for(let i = 0; i < headTags.length; i++ ){
			head.removeChild(headTags[i]);
		}
		const newHeadTags = newPageHead.querySelectorAll(removeHeadTags);
		for(let i = 0; i < newHeadTags.length; i++ ){
			head.appendChild(newHeadTags[i]);
		}
    });
	
    Barba.Pjax.getTransition = function() {
    	return ShutterAnimation;
    };
});
