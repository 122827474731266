export function loading(callback){
    const images = document.getElementsByTagName('img');
    const percent = document.getElementById('percent-text');
    const loading = document.getElementById('loading');
    //const bar = document.getElementById('bar');

	let finish = false;
    let leng = images.length;
    let imgCount = 0;
    let baseCount = 0;
    let current;
    
    for (let i = 0; i < images.length; i++) {
      let img = new Image();
      img.onload = function() {
          imgCount += 1;
      }
      img.onerror = function() {
          imgCount += 1;
      }
      img.src = images[i].src;
    };

    function disp(result){
        setTimeout(function(){
            let num = parseInt(percent.innerHTML);
            if(result > num | num < 100){
                percent.innerHTML = num +1;
                //bar.style.width = num + '%';
                disp(result);
            }else{
                nowLoading();
            }

            if(num == 100){
				if(!finish){
					finish = true;
					loading.classList.add('active');
					setTimeout(function(){
						loading.style.display = 'none';
						callback();
					},1600);
				}
            }
        },10);
    }

    function nowLoading(){
      if(baseCount <= imgCount) {
          current = Math.floor(baseCount / leng * 100);
          //percent.innerHTML = current;
          disp(current);
          baseCount ++;
      }
    }
	
	if(leng == 0){
		loading.style.display = 'none';
	}else{
		nowLoading();
	}
	
}