export function index(){
	if(document.getElementById('site-firstview')){
		const progress = document.getElementById('progressbar');
		const first_txt = document.getElementsByClassName('first-number');
		const last_txt  = document.getElementsByClassName('last-number');
		let flag = false;
		const swiper = new Swiper('.swiper-container',{
			slidesPerView: 1,
			spaceBetween: 0,
			effect: 'fade',
			speed: 2000,
			loop: true,
			autoplay: {
				delay: 4000,
				disableOnInteraction: false,
			},
			on: {
				init: function(){
					progress.classList.add('active','first');
					last_txt[0].innerText = this.slides.length - 2;
					flag = true;

					const catchtxt = document.querySelector('#first-catch');
					const subtxt = document.querySelector('#second-catch');
					const txtchange =  catchtxt.innerHTML.replace(/<br>/g, '_');
					const txt =  txtchange;
					catchtxt.innerHTML = '';
					txt.split('').forEach(function(c) {
						if(c == '_'){
							catchtxt.innerHTML += '<br>';
						}else{
							catchtxt.innerHTML += '<span>'+c+'</span>';
						}
					});
					setTimeout(function(){
						catchtxt.classList.add('is-active');
					},200)
					setTimeout(function(){
						subtxt.classList.add('is-active');
					},1600)

				},
				slideChange: function(){
					if(flag){
						progress.classList.remove('active','first');
						setTimeout(function(){
							progress.classList.add('active');
							first_txt[0].innerText = swiper.realIndex + 1;
						},200);
					}
				},
			},
		});
	}
}